import ISpreadsheetProductDto from "@/chipply/interface/i-spreadsheet-product-dto";
import ITreeItem from "@/chipply/interface/i-tree-item";
import IProcessCost from "@/chipply/process/IProcessCost";
import { ZeroSellPriceDisplay } from "@/chipply/store/ZeroSellPriceDisplay";
import { ITextValue, TaxType, Utils } from "chipply-common";
import { EventBus } from "../EventBus";
import { ISelectableListItem } from "./ISelectableListItem";
import { ProductsSpreadsheetEvents } from "../products/ProductsSpreadsheetEvents";
import ProductColorReviewViewModel from "../products/ProductColorReviewModel";
import IImageSettingsCollection from "../products/IImageSettingsCollection";
import ProductReviewImageViewModel from "../products/ProductReviewImageViewModel";
import IProductReviewModel from "../products/IProductReviewModel";
import ProductColorReviewModel from "../products/ProductColorReviewModel";
import ArtworkReviewImageViewModel from "../products/ArtworkReviewImageViewModel";
import IArtworkSettingModel from "../products/IArtworkSettingModel";
import IEventProductColorReviewModel from "../products/IProductColorReviewModel";
import Decimal from "decimal.js";

export default class SpreadsheetProductViewModel {
    [key: string]: any;
    get taxTypeDisplay() {
        const taxRate = this.taxRate;
        const taxType = this.taxType;
        if (taxType === TaxType.Auto) {
            return "Auto";
        }
        if (taxType === TaxType.TaxExempt) {
            return "Exempt";
        }
        if (taxType === TaxType.Custom) {
            return "Custom";
        }
        return !taxRate ? "%" : taxRate + "%";
    }

    get hideVendor() {
        return this._hideVendor;
    }

    set hideVendor(value: boolean) {
        this._hideVendor = value;
        this.setShowDetailsMenu();
    }

    get hideStyle() {
        return this._hideStyle;
    }

    set hideStyle(value: boolean) {
        this._hideStyle = value;
        this.setShowDetailsMenu();
    }

    get hideProductName() {
        return this._hideProductName;
    }

    set hideProductName(value: boolean) {
        this._hideProductName = value;
        this.setShowDetailsMenu();
    }

    get required() {
        return this._required;
    }

    set required(value: boolean) {
        this._required = value;
        this.setShowDetailsMenu();
    }

    public isArtworkInvalid = false;
    public costStyle: object = {};
    public hasInventory = false;
    public vendorName!: string;
    public isVendorFromFeed!: boolean;
    public vendorId!: number;
    public eventProductId!: number;
    public colorSizeAssigned = false;
    public sizeNames: string[] = [];
    public colors: IEventProductColorReviewModel[] = [];
    public selectedColorId!: number;
    public selectAllChecked = false;
    public imageUrl!: string;
    public categoryTree!: number[];
    public hidePersonalization!: boolean;
    public styleNumber!: string;
    public productName!: string;
    public processName!: string;
    public sortOrder!: string | number;
    public processCostDisplay: string;
    public msrpDisplay: string;
    public combinedDisplay: string;
    public fundraisingPercentDisplay: string;
    public sellPriceDisplay: number;
    public autoPricedPrice!: number;
    public priceManuallyAdjusted!: boolean;
    public categories!: ITreeItem[];
    public processId!: number;
    public categoryIds!: number[];
    public processes: Array<{ text: string; value: number }> = [];
    public useFeedsBasedQty!: boolean;
    public availableColorSizePercentage = "";
    public hasOrders = false;
    public vendorDiscountUnset!: boolean;
    public msrpPricingTierIndividual: number;

    public imagesByColors: ProductColorReviewViewModel[] = [];
    public colorImagesInEditing: ProductReviewImageViewModel[] = [];
    public artworkImagesInEditing: ArtworkReviewImageViewModel[] = [];
    public imageSettings: IImageSettingsCollection = [];
    public artworkSettings: IArtworkSettingModel[] = [];
    public primaryImageNumber = 0;
    private _isReviewChanged = false;
    public get isReviewChanged(): boolean {
        return this._isReviewChanged || (this.children && this.children.some((c) => c.isReviewChanged));
    }
    public set isReviewChanged(val: boolean) {
        this._isReviewChanged = val;
    }

    public get showDetailsMenu() {
        const hideStyle = this._hideStyle;
        const hideVendor = this._hideVendor;
        const required = this._required;
        const hideProductName = this._hideProductName;
        const ignoreSizeUpcharges = this.ignoreSizeUpcharge;
        const zeroSellPriceOtherThanDefault = this.zeroSellPriceDisplay !== ZeroSellPriceDisplay.DisplayAsZero;
        return (
            hideStyle ||
            hideVendor ||
            required ||
            hideProductName ||
            ignoreSizeUpcharges ||
            zeroSellPriceOtherThanDefault
        );
    }
    public showHideOptions!: boolean;

    public ignoreSizeUpcharge!: boolean;
    public taxType!: TaxType;
    public taxRate: number | null = 0;
    public taxMenu!: boolean;
    public groupMarkup!: number;
    public cost!: number;
    public cogs!: number;
    public weight: number | null = null;

    public zeroSellPriceDisplay!: ZeroSellPriceDisplay;
    public zeroSellPriceLabel: string | null = null;

    public children!: SpreadsheetProductViewModel[];
    public selectedProcesses: ISelectableListItem[] = [];
    public processMenuOpen = false;
    public isSelected = false;
    public isReviewMode = false;

    private selectedCategories!: string;
    private basePrice!: number;

    private enabled!: boolean;
    private msrp!: number;
    private processCost!: number;
    private parentCategories: number[];
    private processCosts: IProcessCost;

    private _required!: boolean;
    private _hideVendor!: boolean;
    private _hideStyle!: boolean;
    private _hideProductName!: boolean;

    constructor(
        dto: ISpreadsheetProductDto,
        categories: ITreeItem[],
        processCosts: { [processId: number]: IProcessCost },
        processes: Array<ITextValue<number>>
    ) {
        Object.assign(this, dto);

        this.processes = processes;
        this.processCosts = (processCosts && processCosts[this.processId]) ?? {
            artworkAndRequiredOptionsPrice: 0,
            artworkPrice: 0,
            requiredOptionsPrice: 0,
        };

        this._required = dto.required;
        this._hideStyle = dto.hideStyle;
        this._hideVendor = dto.hideVendor;
        this._hideProductName = dto.hideProductName;
        this.setShowDetailsMenu();

        this.taxMenu = false;
        this.processCostDisplay = Utils.getCurrencyValue(this.processCost);
        this.msrpDisplay = Utils.getCurrencyValue(this.msrp);
        const combinedPrice = new Decimal(this.cost).plus(this.processCost).toNumber();
        this.combinedDisplay = Utils.getCurrencyValue(combinedPrice);
        const fundraisingPercentDisplay = new Decimal(this.groupMarkup).dividedBy(combinedPrice).times(100);
        this.fundraisingPercentDisplay = Utils.getCurrencyValue(fundraisingPercentDisplay.toNumber());
        const sellPriceDisplay = new Decimal(combinedPrice).plus(this.groupMarkup);
        this.sellPriceDisplay = sellPriceDisplay.toNumber();
        this.categoryTree = [];
        this.categories = categories;
        this.parentCategories = this.getParentCategories(categories); // TODO: could optimize
        this.imageUrl = dto.imageUrl ?? "";

        this.msrpPricingTierIndividual = dto.msrpIndividual;
        if (this.colors && this.colors.length > 0) {
            this.selectedColorId = this.colors[0].eventProductColorId;
        }

        this.children = [];

        const childProcesses: Array<ITextValue<number>> = [];
        if (processes) {
            childProcesses.push({ value: 0, text: "Delete" });
            childProcesses.push(...processes);
        }

        if (dto.children) {
            for (const child of dto.children) {
                this.children.push(new SpreadsheetProductViewModel(child, categories, processCosts, childProcesses));
            }
        }

        if (processes) {
            this.resetSelectedProcesses();
        }
    }

    public multipleProcessClosed(accepted: boolean) {
        if (!accepted) {
            this.processMenuOpen = false;
            this.resetSelectedProcesses();
            return;
        }

        const selectedProcessIds: number[] = [];
        for (const process of this.selectedProcesses) {
            if (process.selected) {
                selectedProcessIds.push(process.id);
            }
        }

        this.processMenuOpen = false;
        EventBus.$emit(
            ProductsSpreadsheetEvents.ProductMultipleProcessClosedEvent,
            [this.eventProductId],
            selectedProcessIds
        );
    }

    public updateByReviewModel(productReviewModel: IProductReviewModel, skipUpdateHidePersonalization: boolean) {
        if (!skipUpdateHidePersonalization) {
            this.hidePersonalization = productReviewModel.hidePersonalization;
        }
        this.colors = productReviewModel.colors;
        this.sizeNames = productReviewModel.sizes;
        this.imagesByColors = productReviewModel.colors.map((c) => new ProductColorReviewModel(c));
        this.imageSettings = [...productReviewModel.imageSettings];
        this.artworkSettings = [...productReviewModel.artworkSettings];
        this.imagesByColors.forEach((c) => {
            c.colorImages.forEach((i) => {
                const imageSetting = this.imageSettings.find((s) => s.imageNumber === i.imageNumber);
                if (imageSetting) {
                    if (imageSetting.sortOrder === 1) {
                        i.isPrimary = true;
                    } else {
                        i.isPrimary = false;
                    }
                    i.isVisible = imageSetting.isVisible;
                }
            });
            c.artworkImages.forEach((i) => {
                const artworkSetting = this.artworkSettings.find((s) => s.eventArtworkId === i.eventArtworkId);
                if (artworkSetting) {
                    i.isVisible = artworkSetting.isVisible;
                }
            });
        });
        const primaryImageSetting = this.imageSettings.find((i) => i.sortOrder === 1);
        this.primaryImageNumber = primaryImageSetting ? primaryImageSetting.imageNumber : 0;

        if (this.colors && this.colors.length) {
            // set the color which has the minimum sort order as the primary color
            const minSortOrder = Math.min(...this.imagesByColors.map((c) => c.sortOrder));
            const primaryColor = this.imagesByColors.find((c) => c.sortOrder === minSortOrder);
            this.selectedColorId = primaryColor ? primaryColor.eventProductColorId : this.colors[0].eventProductColorId;
            this.updateSelectedColor(this.selectedColorId);
        }
        this.isReviewChanged = false;
    }

    public toReviewModels(): IProductReviewModel[] {
        this.collectReviewImageColorSettingChanges();
        const colors: IEventProductColorReviewModel[] = this.calculateColors();
        const reviewModels: IProductReviewModel[] = [];
        reviewModels.push({
            eventProductId: this.eventProductId,
            hidePersonalization: this.hidePersonalization,
            colors: colors,
            sizes: this.sizeNames,
            imageSettings: this.imageSettings,
            artworkSettings: this.artworkSettings,
        });

        if (this.children) {
            this.children.forEach((c) => {
                reviewModels.push(...c.toReviewModels());
            });
        }
        return reviewModels;
    }

    private calculateColors() {
        let colors: IEventProductColorReviewModel[] = [];
        colors = this.imagesByColors.map((c) => c.toModel());
        if (colors.length == 0) {
            colors = this.colors;
        }
        let sortOrder = 1;
        for (const item of colors) {
            if (item.eventProductColorId === this.selectedColorId) {
                item.sortOrder = 1;
            } else {
                sortOrder += 1;
                item.sortOrder = sortOrder;
            }
        }
        return colors;
    }

    public updateSelectedColor(colorId: number) {
        if (this.selectedColorId !== colorId) {
            this.isReviewChanged = true;
            this.selectedColorId = colorId;
        }

        const color = this.imagesByColors.find((c) => c.eventProductColorId === this.selectedColorId);
        if (color) {
            this.colorImagesInEditing = color.colorImages;
            this.artworkImagesInEditing = color.artworkImages;
        }
    }

    public toDto(): ISpreadsheetProductDto {
        const childDtos: ISpreadsheetProductDto[] = [];
        for (const child of this.children) {
            childDtos.push(child.toDto());
        }

        const dto: ISpreadsheetProductDto = {
            enabled: this.enabled,
            eventProductId: this.eventProductId,
            hidePersonalization: this.hidePersonalization,
            productName: this.productName,
            sortOrder: Number(this.sortOrder),
            styleNumber: this.styleNumber,
            basePrice: this.sellPriceDisplay,
            cogs: this.cogs,
            cost: this.cost,
            children: childDtos,
            groupMarkup: this.groupMarkup,
            msrp: Utils.getNumberFromString(this.msrpDisplay),
            processCost: Utils.getNumberFromString(this.processCostDisplay),
            msrpIndividual: this.msrpPricingTierIndividual,
            autoPricedPrice: this.autoPricedPrice,
            categoryIds: [],
            imageUrl: this.imageUrl,
            priceManuallyAdjusted: this.priceManuallyAdjusted,
            processId: this.processId,
            processName: this.processName,
            useFeedsBasedQty: this.useFeedsBasedQty,
            vendorDiscountUnset: this.vendorDiscountUnset,
            vendorName: this.vendorName,
            isVendorFromFeed: this.isVendorFromFeed,
            vendorId: this.vendorId,
            hideStyle: this.hideStyle,
            hideVendor: this.hideVendor,
            hideProductName: this.hideProductName,
            ignoreSizeUpcharge: this.ignoreSizeUpcharge,
            required: this.required,
            showHideOptions: this.showHideOptions,
            taxRate: this.taxRate,
            taxType: this.taxType,
            weight: Number(this.weight),
            zeroSellPriceLabel: this.zeroSellPriceLabel,
            zeroSellPriceDisplay: this.zeroSellPriceDisplay,
        };

        if (!this.categoryIds) {
            return dto;
        }
        for (const cat of this.categoryIds) {
            if (this.parentCategories.indexOf(cat) > -1) {
                continue;
            }
            dto.categoryIds.push(cat);
        }

        return dto;
    }

    public sellPriceChanged = (newValue: number) => {
        const formattedSellPrice = Utils.getCurrencyValue(newValue);
        const sellPrice = Utils.getNumberFromString(formattedSellPrice);
        this.sellPriceDisplay = sellPrice;
        this.basePrice = sellPrice;
        const cost = this.cost;
        const processCost = Utils.getNumberFromString(this.processCostDisplay);

        const fundraisingDollars = new Decimal(sellPrice).minus(cost).minus(processCost).toNumber();
        this.groupMarkup = fundraisingDollars;
        const costPlusProcessCost = new Decimal(cost).plus(processCost);
        const fundraisingPercent = new Decimal(fundraisingDollars).dividedBy(costPlusProcessCost).times(100).toNumber();
        this.fundraisingPercentDisplay = Utils.getCurrencyValue(fundraisingPercent);
    };

    public priceChanged(recalculateFundraisingDollars?: boolean) {
        const newCost = this.cost;
        const processCost = Utils.getNumberFromString(this.processCostDisplay);
        const combinedCost = new Decimal(newCost).plus(processCost).toNumber();
        let fundraisingDollar = this.groupMarkup;
        let fundraisingPercent = new Decimal(fundraisingDollar).dividedBy(combinedCost).toNumber();
        if (recalculateFundraisingDollars) {
            fundraisingPercent = new Decimal(Utils.getNumberFromString(this.fundraisingPercentDisplay))
                .dividedBy(100)
                .toNumber();
            fundraisingDollar = new Decimal(combinedCost).times(new Decimal(fundraisingPercent)).toNumber();
        }

        this.groupMarkup = fundraisingDollar;
        this.fundraisingPercentDisplay = Utils.getCurrencyValue(new Decimal(fundraisingPercent).times(100).toNumber());
        this.combinedDisplay = Utils.getCurrencyValue(combinedCost);
        this.sellPriceDisplay = new Decimal(combinedCost).plus(fundraisingDollar).toNumber();

        for (const child of this.children) {
            child.cost = this.cost;
            child.priceChanged(recalculateFundraisingDollars);
        }
    }

    public hideOptionsChanged = (newValue: boolean) => {
        let processCost = 0;
        if (newValue) {
            processCost = this.processCosts.artworkPrice;
        } else {
            processCost = this.processCosts.artworkAndRequiredOptionsPrice;
        }

        this.processCostDisplay = Utils.getCurrencyValue(processCost);
        this.priceChanged();
    };

    public recalculatePrices = (newValue: string) => {
        this.priceChanged();
    };

    public fundraisingPercentDisplayChanged = (newValue: string) => {
        this.fundraisingPercentDisplay = newValue;
        this.priceChanged(true);
    };

    protected resetSelectedProcesses() {
        const orderedProcessIds: number[] = [];
        if (this.hasOrders) {
            orderedProcessIds.push(this.processId);
        }

        const selectedProcessIds: number[] = [];
        selectedProcessIds.push(this.processId);
        for (const child of this.children) {
            selectedProcessIds.push(child.processId);
            if (child.hasOrders) {
                orderedProcessIds.push(child.processId);
            }
        }

        this.selectedProcesses = [];
        for (const process of this.processes) {
            this.selectedProcesses.push({
                disabled: orderedProcessIds.indexOf(process.value) > -1,
                id: process.value,
                name: process.text,
                selected: selectedProcessIds.indexOf(process.value) > -1,
            });
        }
    }

    protected setShowDetailsMenu() {
        // this.showDetailsMenu = this._hideStyle || this._hideVendor || this._required || this._hideProductName;
    }

    protected getParentCategories(categories: ITreeItem[]) {
        const parentCats: number[] = [];
        if (categories == null) {
            return [];
        }
        for (const cat of categories) {
            if (!cat.children || cat.children.length === 0) {
                continue;
            }
            parentCats.push(cat.id);
        }

        return parentCats;
    }

    private numberFocused(event: FocusEvent) {
        if (!event) {
            return;
        }
        (event.currentTarget as HTMLInputElement).select();
    }

    private getZeroCostStyle() {
        if (this.cost === 0) {
            return "red";
        }
        if (
            (this.cost !== this.autoPricedPrice && !this.priceManuallyAdjusted) ||
            (this.vendorDiscountUnset && !this.priceManuallyAdjusted)
        ) {
            return "yellow";
        } else {
            return "";
        }
    }

    private getSellPriceColor() {
        return this.basePrice < 0 ? "red" : "";
    }

    private getWeightColor() {
        const validationColor = "red";
        if (this.weight === null) {
            return validationColor;
        } else if (this.weight <= 0) {
            return validationColor;
        }
        return "";
    }

    public getSizeNamesDisplay() {
        if (this.sizeNames && this.sizeNames.length > 0) {
            return this.sizeNames.join(", ");
        }
        return "N/A";
    }

    public getCategoriesDisplay() {
        const categoryNames: string[] = [];
        if (this.categoryIds && this.categoryIds.length > 0) {
            for (const categoryId of this.categoryIds) {
                const categoryToFind = this.categories.find((c) => c.id === categoryId);
                if (categoryToFind) {
                    categoryNames.push(categoryToFind.name);
                    continue;
                }
                for (const category of this.categories) {
                    const child = category.children.find((c) => c.id === categoryId);
                    if (child) {
                        categoryNames.push(child.name);
                        continue;
                    }
                }
            }
        }
        return categoryNames.join(", ");
    }

    public colorImageVisibleChanged(theImage: ProductReviewImageViewModel) {
        const isVisible = theImage.isVisible;
        this.imagesByColors.forEach((color) => {
            const imageWithSameNumber = color.colorImages.find((i) => i.imageNumber === theImage.imageNumber);
            if (imageWithSameNumber) {
                imageWithSameNumber.isVisible = isVisible;
            }
        });

        // set image settings
        const theImageSetting = this.imageSettings.find((i) => i.imageNumber === theImage.imageNumber);
        if (theImageSetting) {
            theImageSetting.isVisible = isVisible;
        }

        this.isReviewChanged = true;
    }

    public artworkImageVisibleChanged(theImage: ArtworkReviewImageViewModel) {
        const isVisible = theImage.isVisible;
        this.imagesByColors.forEach((color) => {
            const imageWithSameArtwork = color.artworkImages.find((i) => i.eventArtworkId === theImage.eventArtworkId);
            if (imageWithSameArtwork) {
                imageWithSameArtwork.isVisible = isVisible;
            }
        });

        // set artwork settings
        const theArtworkSetting = this.artworkSettings.find((i) => i.eventArtworkId === theImage.eventArtworkId);
        if (theArtworkSetting) {
            theArtworkSetting.isVisible = isVisible;
        }

        this.isReviewChanged = true;
    }

    public colorImageSelectedChanged(theImage: ProductReviewImageViewModel) {
        const isPrimary = theImage.isPrimary;
        this.imagesByColors.forEach((color) => {
            color.colorImages.forEach((i) => {
                // update image with the same imageNumber
                if (i.imageNumber === theImage.imageNumber) {
                    i.isPrimary = theImage.isPrimary;
                } else if (isPrimary) {
                    // set all others to false
                    i.isPrimary = false;
                }
            });
        });

        this.primaryImageNumber = isPrimary ? theImage.imageNumber : 0;
        this.isReviewChanged = true;
    }

    private collectReviewImageColorSettingChanges() {
        // set image sort order, put the selected image at the begining
        const imageSettingSetToPrimary = this.imageSettings.find((i) => i.imageNumber === this.primaryImageNumber);
        if (imageSettingSetToPrimary) {
            let startSortOrder = 1;
            imageSettingSetToPrimary.sortOrder = startSortOrder;

            // set sort order of other image settings
            this.imageSettings
                .filter((i) => i.imageNumber !== this.primaryImageNumber)
                .sort((a, b) => a.imageNumber - b.imageNumber)
                .forEach((i) => {
                    i.sortOrder = ++startSortOrder;
                });
        }

        // set colors sort order, put the selected color at the begining
        const selectedColor = this.imagesByColors.find((c) => c.eventProductColorId === this.selectedColorId);
        if (selectedColor) {
            let startSortOrder = 1;
            selectedColor.sortOrder = startSortOrder;

            // set sort order of other colors
            this.imagesByColors
                .filter((c) => c.eventProductColorId !== this.selectedColorId)
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .forEach((c) => {
                    c.sortOrder = ++startSortOrder;
                });
        }
    }
}
